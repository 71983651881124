import React , {useEffect} from "react";
import { Routes, Route } from "react-router-dom";
import Navbar from './components/Navbar'
import Home from "./pages/Home";
import Aboutus from "./pages/Aboutus";
import Contactus from "./pages/Contactus";
import Servicespage from "./pages/Servicespage";
import Gallery from "./pages/Gallery";
import ScrollToTop from './ScrollToTop'
import "./App.css";

function App() {

  useEffect(() => {
    // Add Google Tag script to the document
    const script1 = document.createElement("script");
    script1.async = true;
    script1.src = "https://www.googletagmanager.com/gtag/js?id=AW-16656332445";
    document.head.appendChild(script1);

    const script2 = document.createElement("script");
    script2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'AW-16656332445');
    `;
    document.head.appendChild(script2);
  }, []);

  return (
    <div className="App">
      <ScrollToTop/>
      <div className='w-full fixed top-0 left-0 z-10'>
        <Navbar />
      </div>
      <div className='w-full relative z-0'>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<Aboutus />} />
        <Route path="/contact" element={<Contactus />} />
        <Route path="/service" element={<Servicespage />} />
        <Route path="/gallery" element={<Gallery />} />
      </Routes>
      </div>
    </div>
  );
}

export default App;
